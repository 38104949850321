import React from "react"
import styles from "./index.module.scss"
import { Link } from "gatsby"

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion"

import extension from "../img/icons/icon_extension.svg"
import integration from "../img/icons/icon_integration.svg"

export default class FaqWeb extends React.Component {
  render() {
    const content = [
      {
        title: `Do I have to report my time to my task/project management system when using DueFocus?`,
        description: (
          <div>
            <p>
              When you report a task in DueFocus, the tracked time and comment
              (if supported by system) for this task will be automatically
              reported in the task/project management system integrated with
              DueFocus.
            </p>
            <p>
              You do not need to report time in the project/task management
              system additionally.
            </p>
          </div>
        ),
      },
      {
        title: "Why do I need to add estimated time when I create a new task?",
        description: (
          <div>
            <p>
              “Estimated time” is a mandatory field in certain project and task
              management systems, so it must be specified during the creation of
              a task. Also, the “Estimated time” indication will allow you more
              accurately plan your work for the current task.
            </p>
          </div>
        ),
      },
      {
        title:
          "I cannot find my task(s) in a project through DueFocus. What's wrong?",
        description: (
          <div>
            <p>
              DueFocus displays only open tasks and assigned on you by default
              while adding task to plannings or creating manual report. To
              display unassigned tasks please mark/unmark Unassigned checkbox
              after selecting project.
            </p>
            <p>Verify that:</p>
            <p>
              1. Your task is present in your task/project management system;
            </p>
            <p>
              2. Task is opened and not in
              Closed/Done/Cancelled/Rejected/Archived status;
            </p>
            <p>
              3. Task is assigned on you, or unassigned. Tasks assigned on
              another user are not available for you. But if your system
              supports multiassigning task to several users, and task is
              assigned also on you - it will be displayed;
            </p>
            <p>
              4. There is no problem with your integration. When integration is
              failed - it's marked by red on Settings - Integrations tab both on
              desktop app and Dashboard.;
            </p>
            <br />
            <p>
              If the listed steps above didn't help and you still don't see your
              task, please write to our DueFocus support team via Intercom chat
              in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `I just connected integration, but but my projects and tasks are still not displayed, what I have to do?`,
        description: (
          <div>
            <p>
              Please refresh the page or click on Reload button on Projects
              page. After these steps info will be updated and your projects and
              tasks will be displayed.
            </p>
            <p>
              If the listed steps above didn't help and you still don't see your
              projecs and tasks, please write to our support team via Intercom
              chat in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `Seems my integration has failed and marked as red, I cannot select a project to start a task or create manual report. What's wrong?`,
        description: (
          <div>
            <p>
              If you see red alert on Settings tab on Dashboard or your profile
              icon and Integration item in menu on desktop - it means that there
              is a some problem with your connected integration.
            </p>
            <p>
              Please click on Reload button on Dashboard. If it doesn't help -
              try to reconnect or disconnect you integration and connect again.
            </p>
            <p>
              If these steps don't resolve and issue - please write to our
              DueFocus support team via Intercom chat in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `How to remove my acount?`,
        description: (
          <div>
            <p>
              We don’t have a final deletion of accounts. Over time without
              using it, account changes status to Archived.
            </p>
            <p>
              This is to ensure that if you worked as a team member, and left
              the project, then the project manager and your colleagues had
              information about you.
            </p>
            <p>
              But if you want, we can remove your account data from base. Just
              write to our DueFocus support team via Intercom chat in upper
              right corner.
            </p>
            <br />
            <p>
              Also we are very glad to know was wrong in your work with
              DueFocus? Your feedback will be a massive contribution in our
              improvement.
            </p>
          </div>
        ),
      },
      {
        title: `I haven't found an answer to my question. What should I do? `,
        description: (
          <div>
            <p>
              If you have some troubleshootings, issues, bugs, questions or
              suggestions, please write to our DueFocus support team via
              Intercom chat in upper right corner.
            </p>
            <p>
              Also you can add requests and feedback here:{" "}
              <a href="https://duefocus.canny.io">https://duefocus.canny.io</a>
            </p>
          </div>
        ),
      },
    ]
    return (
      <>
        <div className={styles.main}>
          {/* <div id="extension" className={styles.title}>
            <img src={extension} alt="extension" />
            <span>Extension</span>
          </div>
          <div className={styles.accordion}>
            <Accordion>
              {content.map((item, n) => (
                <AccordionItem key={n}>
                  <AccordionItemTitle>
                    <span className="acc_header u-position-relative">
                      {item.title}
                      <div className="accordion__arrow" role="presentation" />
                    </span>
                  </AccordionItemTitle>
                  <AccordionItemBody>{item.description}</AccordionItemBody>
                </AccordionItem>
              ))}
            </Accordion>
          </div> */}
          <div id="integrations" className={styles.title}>
            <img src={integration} width="auto" height="auto" alt="integration" />
            <h2>Integrations</h2>
          </div>
          <div className={styles.accordion}>
            <Accordion>
              {content.map((item, n) => (
                <AccordionItem key={n}>
                  <AccordionItemTitle>
                    <span className="acc_header u-position-relative">
                      {item.title}
                      <div className="accordion__arrow" role="presentation" />
                    </span>
                  </AccordionItemTitle>
                  <AccordionItemBody>{item.description}</AccordionItemBody>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </>
    )
  }
}
