import React from "react"
import styles from "./index.module.scss"

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion"

import web from "../img/icons/Icom_web.svg"

export default class FaqWeb extends React.Component {
  render() {
    const content = [
      {
        title: "How can I register and start using DueFocus?",
        description: (
          <div>
            <p>
              You can register account on Landing or Login page (
              <a href="https://web.duefocus.com">https://web.duefocus.com</a>)
              by using{" "}
              <span className={styles.openModal} onClick={this.openSignUp}>
                “Sign Up”
              </span>{" "}
              button
            </p>
            <p>
              After registration you'll be redirected to Onboarding to finish
              configuring of your profile;
            </p>
            <p>
              There are two ways to Sign Up: - by email or by third-party
              accounts: Trello, Github, Gitlab or Google.
            </p>
            <p>
              1. Sign Up via email - you just need to enter email address and
              password. After that you'll receive confirmation email to you
              mailbox;
            </p>
            <p>
              2. Sign Up via third-party account - click on Trello, Github,
              Gitlab or Google icon. In this case you'll get temporary password
              to mailbox so you can also sign in via login/password. Besides,
              your account will be already confirmed and integration with
              projects and tasks/issues will automatically connected;
            </p>
          </div>
        ),
      },
      {
        title: "What first should I do after creating DueFocus account?",
        description: (
          <div>
            <p>
              First of all we recommend to confirm your account from "Confirm
              your email" in your mailbox, otherwise some features will be
              limited and your account will be expired in 48 hours.
            </p>
            <p>
              User has 30 days to confirm his account else it will be blocked
              permanently.
            </p>
          </div>
        ),
      },
      {
        title: "What is DueFocus Dashboard and how to use it?",
        description: (
          <div>
            <p>
              Dashboard is web application where you can observe your or
              employees activities, weekly/montly reports, screenshots.
            </p>
            <p>
              Also you're able to set project settings, report time manually by
              clicking on the timeline as on desktop application, and create
              invoices for clients.
            </p>
          </div>
        ),
      },
      {
        title: "Do I need install desktop application?",
        description: (
          <div>
            <p>
              Yes, you need a desktop application if you need to track time,
              report spent time into task, analyze your activities and create
              screenshots.
            </p>
            <p>
              Desktop can be downloaded on Downloads tab on Landing page, while
              Onboarding and in profile menu on Dashboard.
            </p>
            <br />
            <p>
              If you just report time manually, or you are a manager, don't need
              to track time and just monitor employee activities and create
              invoices - desktop application is not required for you.
            </p>
          </div>
        ),
      },
      {
        title: `Do I have to report my time to my task/project management system when using DueFocus?`,
        description: (
          <div>
            <p>
              When you report a task in DueFocus, the tracked time and comment
              (if supported by system) for this task will be automatically
              reported in the task/project management system integrated with
              DueFocus.
            </p>
            <p>
              You do not need to report time in the project/task management
              system additionally.
            </p>
          </div>
        ),
      },
      {
        title: "Why do I need to add estimated time when I create a new task?",
        description: (
          <div>
            <p>
              “Estimated time” is a mandatory field in certain project and task
              management systems, so it must be specified during the creation of
              a task. Also, the “Estimated time” indication will allow you more
              accurately plan your work for the current task.
            </p>
          </div>
        ),
      },
      {
        title: "Can I remove tracked time from timeline?",
        description: (
          <div>
            <p>
              No, deleting timesheets is unavailable. DueFocus is a daily
              planner, all your day time can be used only once and you can't
              change it after.
            </p>
          </div>
        ),
      },
      {
        title:
          "I cannot find my task(s) in a project through DueFocus. What's wrong?",
        description: (
          <div>
            <p>
              DueFocus displays only open tasks and assigned on you by default
              while adding task to plannings or creating manual report. To
              display unassigned tasks please mark/unmark Unassigned checkbox
              after selecting project.
            </p>
            <p>Verify that:</p>
            <p>
              1. Your task is present in your task/project management system;
            </p>
            <p>
              2. Task is opened and not in
              Closed/Done/Cancelled/Rejected/Archived status;
            </p>
            <p>
              3. Task is assigned on you, or unassigned. Tasks assigned on
              another user are not available for you. But if your system
              supports multiassigning task to several users, and task is
              assigned also on you - it will be displayed;
            </p>
            <p>
              4. There is no problem with your integration. When integration is
              failed - it's marked by red on Settings - Integrations tab both on
              desktop app and Dashboard.;
            </p>
            <br />
            <p>
              If the listed steps above didn't help and you still don't see your
              task, please write to our DueFocus support team via Intercom chat
              in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `How can I report time manually?`,
        description: (
          <div>
            <span className={styles.accordion_title}>
              You can contact our “Help Center”:
            </span>
            <p>To use manual reporting, you should:</p>
            <p>
              1. By going to the “Info” section and clicking the “Help Center”
              button;
            </p>
            <p>
              2. Select project and task, if task is unassigned and not
              displayed in list - mark "Show unassigned" checkbox;
            </p>
            <p>
              2a. As alternative to step 2, paste task URL in search field
              instead searching
            </p>
            <p>3. Fill comment and click “Submit” form</p>
            <br />
            <p>
              Blue timesheet will be created and time will be reported into
              task.
            </p>
            <p>
              Note that amount of allowed manually reported time can be limited
              by project settings.
            </p>
          </div>
        ),
      },
      {
        title: `What is Cold Start?`,
        description: (
          <div>
            <p>
              Cold Start is feature which allows start tracking time much faster
              without setting task and planned time, just start track time in
              one click and reassign time into your task later.
            </p>
            <p>
              Also you can turn on "Cold Start on System Start" switcher, and
              time will begin trackin automatically on system startup.
            </p>
            <p>
              This feature is useful when you need to fast begin tracking, in
              cases when you didn't get yet a task from your manager but you
              already need to work, or when there is a trouble with your
              task/project management system.
            </p>
            <p>
              While Cold Start is not reassigned - all your activities and
              screenshots are private and not visible for another team members
              and managers. After reassigning Cold Star all your information
              becomes available.
            </p>
          </div>
        ),
      },
      {
        title:
          "How to launch Cold Start tracking time and reassign it to task?",
        description: (
          <div>
            <p>
              To launch Cold Start tracking click on Start button on the main
              screen of desktop application. Also you can turn on "Cold Start on
              System Start" switcher, and time will begin tracking automatically
              on system startup.
            </p>
            <p>
              Time will begin tracking with creating cyan timesheets on the
              timeline. You may set screenshot frequency in web application on
              "Сold Project" settings page and Idle detection on web and desktop
              appication.
            </p>
            <p>To report Cold start you have to:</p>
            <p>- On desktop app:</p>
            <p>1. Click on blue "Report" button near Cold Start timer;</p>
            <p>
              2. Select project and task (note that Cold Project is your private
              project and will not visible to anyone);
            </p>
            <p>
              3. Select how much you need to report time in
              selector(optionally), leave comment and click on "Assign Time"
              button.
            </p>
            <p>- On Dashboard:</p>
            <p>1. Go to Time - Timeline (or Reports);</p>
            <p>
              2. Select date with not reassigned time (dates are marked in
              blue);
            </p>
            <p>
              3. Select cyan timesheets on timeline (for separated reporting) or
              click on "Assign Cold Time" button (to report all time into one
              task);
            </p>
            <p>
              4. Select project and task (note that Cold Project is your private
              project and will not visible to anyone);
            </p>
            <p>
              5. Select how much you need to report time in by timepickers
              (optionally), leave comment and click on "Submit" button.
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: Cold Project is temporary private project just for settings
              while tracking Cold Start and for reporting redundant cold tracked
              time. Please don't report time into it if you are working on a
              project with manager and other employees, otherwise they cannot
              see your activities.
            </span>
          </div>
        ),
      },
      {
        title: "What is Focus feature?",
        description: (
          <div>
            <p>
              Focus is a unique Machine Learning Algorithm for assessing the
              involvement and focus of a person at work in the process of
              working on his task from integration task managers (Jira. Trello,
              Asana.CickUp etc).
            </p>
            <p>
              We just begun collecting data and ML still learning, as soon as we
              collect information we classify the assessment and the norms of
              work for each individual role, now it trained by users, but in the
              future we can automate it.
            </p>
          </div>
        ),
      },
      {
        title: `Why i don't see any activities of my employee on Dashboard, but I know that he tracked time at this moment?`,
        description: (
          <div>
            <p>
              Here are most often reasons, when manager cannot see timesheets of
              his employee:
            </p>
            <p>
              1. User tracked Cold Start, and still not reassigned it to task;
            </p>
            <p>
              2. User tracked Cold Start, and automatically reassigned to
              personal private Cold Project without selecting a work project;
            </p>
            <p>
              3. User reassigned Cold Start to a different project where manager
              is absent;
            </p>
            <p>
              4. User tracked time mistakely to a different project where
              manager is absent.
            </p>
          </div>
        ),
      },
      {
        title: `What’s Other activity?`,
        description: (
          <div>
            <p>
              Other activity refers to work done in applications and websites
              for which the keywords were not listed in the “Work”,
              “Communication”, “Learning”, and “Entertainment” categories.
            </p>
            <p>
              You can drag & drop any title to needed category in project
              settings.
            </p>
          </div>
        ),
      },
      {
        title:
          "Why DueFocus recognizes our activities as Respite/Useless?. Our work is based on social networks and we use Youtube as tutorials.",
        description: (
          <div>
            <p>
              Activity categories are set by default in project settings. In
              most of cases Youtube, social networks, internet stores, fun
              services etc are useless for employees and distract them from main
              job.
            </p>
            <p>
              But if you have specific workflow on your project - you can easily
              change category by moving titles from one category to another.
            </p>
            <p>
              Also you may copy saved changes for categories from one project to
              another.
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: Only members with manager rights can edit project settings
              (Gear button is marked as blue for managers)
            </span>
          </div>
        ),
      },
      {
        title: `How can i set project settings for me and employees?`,
        description: (
          <div>
            <p>In projects settings you are able to set:</p>
            <p>1. Screenshot frequency per 10 minutes (1x, 2x, 3x);</p>
            <p>2. Pay Rate for each member;</p>
            <p>3. Max daily working hours (up to 24);</p>
            <p>
              4. Max manually reported hours (from 0 to 24, but not more than
              max daily working value);
            </p>
            <p>5. Idle detection (Off, 1 min , 5 min, 10 min, 15 min);</p>
            <p>6. Adding/disabling manager rights to project;</p>
            <p>
              7. Edit activities categories by changing keywords in (Work,
              Communication, Learning, Entertainment);
            </p>
            <p>8. Change task status (Comming soon...);</p>
            <p>
              9. Enable browser notifications about users actions in a project;
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: Only members with manager rights can edit project settings
              (Gear button is marked as blue for managers)
            </span>
          </div>
        ),
      },
      {
        title: `How to change currency for Pay Rate an Invoices from USD to another one?`,
        description: (
          <div>
            <p>
              At this moment only USD is available. Later we plan to add
              posiibility to change currency to another.
            </p>
          </div>
        ),
      },
      {
        title: `How to enable screenshotting?`,
        description: (
          <div>
            <p>
              First of all user should connect his Google Drive or Dropbox
              storage. Note that two storages cannot be connected to one
              account, and the same storage cannot be connected to different
              DueFocus accounts.
            </p>
            <p>
              Also user with manager rights in project should set screenshot
              frequency more than "None". manager can set common frequency for
              all users in a project or set for separated users individually.
            </p>
            <p>
              Users can observe their own screenshots on Time - Timeline tab on
              Dashboard. Manager can observe screenshots of his employees on
              Team Pulse - Team Screens and select required user in selector.
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: If screenshot frequency is set to None, user can enable
              screenshotting on desktop by turning on switcher in menu on
              "Screenshot" item
            </span>
          </div>
        ),
      },
      {
        title: `How many screenshots DueFocus captures during tracking?`,
        description: (
          <div>
            <p>
              You can set frequency of screenshotting in project settings. You
              can set 1x, 2x or 3x screenshots per 10 minutes, or set 'None' and
              screenshot will not be made
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: If screenshot frequency is set to None, user can enable
              screenshotting on desktop by turning on switcher in menu on
              "Screenshot" item
            </span>
          </div>
        ),
      },
      {
        title: `Can I delete some of my screenshots?`,
        description: (
          <div>
            <p>
              You can delete screenshots if “Delete screenshots” option is
              switched “On” in the project settings. To get access to the
              project settings you should have manager rights.
            </p>
          </div>
        ),
      },
      {
        title: `I don't see my/employee's screenshots. What's wrong?`,
        description: (
          <div>
            <p>
              First of all, verify that employee connected screenshot storage,
              otherwise screenshots will not created. Also recheck screenshot
              frequency (common and for this user) in project settings.
            </p>
            <p>
              The other reason may be that user tracked time va Cold Start -
              feature for private tracking, and can set cold settings other than
              alredy set in project for reassigning.
            </p>
            <p>
              By default screenshot frequency set to "None". If user missed to
              set screenshot frequency for Cold Start - screenshots will not be
              created even after reassigning to a project with enabled
              screenshotting.
            </p>
          </div>
        ),
      },
      {
        title: `Can I remove project from Projects list?`,
        description: (
          <div>
            <p>
              Depending on what kind of project are you using. First of all go
              to the Projects page on Dashboard and:
            </p>
            <p>
              1. If you use embedded DueFocus projects - you can remove it
              without any problems, by clicking on the bucket icon in project
              card on Projects page.
            </p>
            <p>
              2. If you use Project from third-party Integration - you can't
              remove it from DueFocus, but you can hide it in Projects list by
              clicking on eye icon.
            </p>
            <p>
              The projects and tasks from hidden projects will not be displayed
              in DueFocus. Later you can restore and unhide it easily by click
              on Eye icon for hidden projects. To observe hidden projects just
              change selection from "Active projects" to "Archived projects"
              near search field.
            </p>
          </div>
        ),
      },
      {
        title: `How can I invite new people to my project in DueFocus?`,
        description: (
          <div>
            <p>
              Navigate to Projects page and click on "+" near needed project
              where you have manager rights and invite users by email. User will
              receive invitation email with temporary password to continue
              creating his profile.
            </p>
            <p>
              If user is already registered in DueFocus and present in other
              project common with you - he will be displayed in "DueFocus
              members" selector.
            </p>
            <p>
              In cases if you need to invite users in project from third-party
              integration, the list of non-DueFocus users from integration will
              be displayed.
            </p>
            <p>
              Also some integrations allows you invite colleagues from its
              system when you connect it to DueFocus at first time.
            </p>
            <p>
              Note: Some of integrations don't give member emails (e.g Trello),
              but when user from your project signs up to DueFocus and connect
              integration - he will automatically apeared as project member in
              DueFocus.
            </p>
            <p>
              As alternative - you may create a team on Team Pulse and invite
              users by email. After connecting integration they will
              automatically become as colleagues in DueFocus
            </p>
          </div>
        ),
      },
      {
        title: `What is Team Pulse and how to create a team?`,
        description: (
          <div>
            <p>
              Team Pulse allows you to create teams and monitor all your team
              members activities in real time.
            </p>
            <p>
              Just click on "Add Team" button on Team Pulse page, select already
              present in DueFocus users or invite new by email.
            </p>
            <p>
              After that you and all your members can observe activities of each
              other, who works on which task and how much time, or even has no
              activities for today.
            </p>
          </div>
        ),
      },
      {
        title: `I just connected integration, but but my projects and tasks are still not displayed, what I have to do?`,
        description: (
          <div>
            <p>
              Please refresh the page or click on Reload button on Projects
              page. After these steps info will be updated and your projects and
              tasks will be displayed.
            </p>
            <p>
              If the listed steps above didn't help and you still don't see your
              projecs and tasks, please write to our support team via Intercom
              chat in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `Seems my integration has failed and marked as red, I cannot select a project to start a task or create manual report. What's wrong?`,
        description: (
          <div>
            <p>
              If you see red alert on Settings tab on Dashboard or your profile
              icon and Integration item in menu on desktop - it means that there
              is a some problem with your connected integration.
            </p>
            <p>
              Please click on Reload button on Dashboard. If it doesn't help -
              try to reconnect or disconnect you integration and connect again.
            </p>
            <p>
              If these steps don't resolve and issue - please write to our
              DueFocus support team via Intercom chat in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `How to remove my acount?`,
        description: (
          <div>
            <p>
              We don’t have a final deletion of accounts. Over time without
              using it, account changes status to Archived.
            </p>
            <p>
              This is to ensure that if you worked as a team member, and left
              the project, then the project manager and your colleagues had
              information about you.
            </p>
            <p>
              But if you want, we can remove your account data from base. Just
              write to our DueFocus support team via Intercom chat in upper
              right corner.
            </p>
            <br />
            <p>
              Also we are very glad to know was wrong in your work with
              DueFocus? Your feedback will be a massive contribution in our
              improvement.
            </p>
          </div>
        ),
      },
      {
        title: `I haven't found an answer to my question. What should I do? `,
        description: (
          <div>
            <p>
              If you have some troubleshootings, issues, bugs, questions or
              suggestions, please write to our DueFocus support team via
              Intercom chat in upper right corner.
            </p>
            <p>
              Also you can add requests and feedback here:{" "}
              <a href="https://duefocus.canny.io">https://duefocus.canny.io</a>
            </p>
          </div>
        ),
      },
    ]
    return (
      <>
        <div className={styles.main}>
          <div id="web" className={styles.title}>
            <img src={web} width="auto" height="auto" alt="web" />
            <h2>Web</h2>
          </div>
          <div className={styles.accordion}>
            <Accordion>
              {content.map((item, n) => (
                <AccordionItem key={n}>
                  <AccordionItemTitle>
                    <span className="acc_header u-position-relative">
                      {item.title}
                      <div className="accordion__arrow" role="presentation" />
                    </span>
                  </AccordionItemTitle>
                  <AccordionItemBody>{item.description}</AccordionItemBody>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </>
    )
  }
}
