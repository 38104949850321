import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import ProgressLine from '../components/Common/srollProgressLine'
import Footer from '../components/Footer/footer'
import dueFocusLogoSoc from '../components/img/duefocus-social-logo.jpg'
import FAQpage from '../components/FAQpage/index'
import { Helmet } from "react-helmet";

// const content = `DueFocus time tracking software FAQ. Find the answers to all questions that may occur during tracking hours.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const FAQ = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      {/* <title>DueFocus Work Hours Tracking FAQ</title> */}
      {/* <title>FAQ - duefocus</title> */}
      <title>Duefocus is a free time tracker for remote teams</title>
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Duefocus is a free time tracker for remote teams" />
      <meta property="og:description" content={content} />
      <meta property="og:url" content="https://www.duefocus.com/" />
      <meta property="og:image" content={dueFocusLogoSoc} />
      <meta name="description" content={content} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/faq/" />
    </Helmet>
    <Header />
    <ProgressLine />
    <FAQpage />
    <Footer />
  </>
)

export default FAQ