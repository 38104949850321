import React from "react"
import styles from "./index.module.scss"
import Web from "./FaqWeb"
import EmptyBlock from '../Header/emptyBlock'
import Extension from "./FaqExtension"
import Form from "../Integrations/FormBlock/index"
import Footer from "../LastBlock/Footer"

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion"

import desktop from "../img/icons/icon_descktop.svg"

export default class FAQpage extends React.Component {
  // showModal = () => {
  //   const modal = document.getElementById('modal-sign-up');
  //   modal.className ='show-modal';
  //   document.body.className = 'modal-active';
  // }
  openSignUp = () => {
    return (window.location.href = "/sign-up/")
  }
  goToDesktop = () => {
    window.scrollTo(0, 311)
  }
  goToWeb = () => {
    let timer
    if (window.pageYOffset < 1739) {
      window.scrollTo(0, window.pageYOffset + 400);
      timer = setTimeout(this.goToWeb, 40);
    } else {
      clearTimeout(timer);
      window.scrollTo(0, 2780);
      // window.location.href = "#web";
    }
  }
  goToExtension = () => {
    let timer
    if (window.pageYOffset < 3653) {
      window.scrollTo(0, window.pageYOffset + 400);
      timer = setTimeout(this.goToExtension, 40);
    } else {
      clearTimeout(timer);
      // window.location.href = "#extension";
      // window.scrollTo(0, 4680);
    }
  }
  goToIntegrations = () => {
    let timer
    if (window.pageYOffset < 4793) {
      window.scrollTo(0, window.pageYOffset + 400);
      timer = setTimeout(this.goToIntegrations, 40);
    } else {
      clearTimeout(timer);
      // window.location.href = "#integrations";
      window.scrollTo(0, 5560);
    }
  }

  render() {
    const mainTitle = "Ask a Question"
    const content = [
      {
        title: "How can I register and start using DueFocus?",
        description: (
          <div>
            <p>
              You can register account on Landing or Login page (
              <a href="https://web.duefocus.com">https://web.duefocus.com</a>)
              by using{" "}
              <span className={styles.openModal} onClick={this.openSignUp}>
                “Sign Up”
              </span>{" "}
              button
            </p>
            <p>
              After registration you'll be redirected to Onboarding to finish
              configuring of your profile;
            </p>
            <p>
              There are two ways to Sign Up: - by email or by third-party
              accounts: Trello, Github, Gitlab or Google.
            </p>
            <p>
              1. Sign Up via email - you just need to enter email address and
              password. After that you'll receive confirmation email to you
              mailbox;
            </p>
            <p>
              2. Sign Up via third-party account - click on Trello, Github,
              Gitlab or Google icon. In this case you'll get temporary password
              to mailbox so you can also sign in via login/password. Besides,
              your account will be already confirmed and integration with
              projects and tasks/issues will automatically connected;
            </p>
          </div>
        ),
      },
      {
        title: "What first should I do after creating DueFocus account?",
        description: (
          <div>
            <p>
              First of all we recommend to confirm your account from "Confirm
              your email" in your mailbox, otherwise some features will be
              limited and your account will be expired in 48 hours.
            </p>
            <p>
              User has 30 days to confirm his account else it will be blocked
              permanently.
            </p>
          </div>
        ),
      },
      {
        title: "Do I need install desktop application?",
        description: (
          <div>
            <p>
              Yes, you need a desktop application if you need to track time,
              report spent time into task, analyze your activities and create
              screenshots.
            </p>
            <p>
              Desktop can be downloaded on Downloads tab on Landing page, while
              Onboarding and in profile menu on Dashboard.
            </p>
            <br />
            <p>
              If you just report time manually, or you are a manager, don't need
              to track time and just monitor employee activities and create
              invoices - desktop application is not required for you.
            </p>
          </div>
        ),
      },
      {
        title: "Which OS does DueFocus desktop application support?",
        description: (
          <div>
            <p>Windows: Windows 7, 8, 8.1, 10, 64-bit only.</p>
            <p>
              Ubuntu: min 16.04, deb packages only - Debian, Gnome, Kubuntu,
              Linuxmint.
            </p>
            <p>MacOS: min MacOS 10.10 Yosemite.</p>
          </div>
        ),
      },
      {
        title: `Do I have to report my time to my task/project management system when using DueFocus?`,
        description: (
          <div>
            <p>
              When you report a task in DueFocus, the tracked time and comment
              (if supported by system) for this task will be automatically
              reported in the task/project management system integrated with
              DueFocus.
            </p>
            <p>
              You do not need to report time in the project/task management
              system additionally.
            </p>
          </div>
        ),
      },
      {
        title: "Do I need to report task everytime I finished work for today?",
        description: (
          <div>
            <p>
              Yes, you need to report task after tracking, otherwise you cannot
              launch it again on next day until it's unreported.
            </p>
            <p>
              But as alternative, you may turn on "Autoreport Daily" switcher in
              preferences on desktop application, and your tracked tasks will be
              automatically reported ith generated comment while launching
              DueFocus on next day.
            </p>
          </div>
        ),
      },
      {
        title: "What is the difference between estimated and planned time?",
        description: (
          <div>
            <p>
              “Estimated time” is the time set to work on the performance of the
              entire task.
            </p>
            <p>
              “Planned time” is the time set to work on the task within a
              particular day.
            </p>
          </div>
        ),
      },
      {
        title: "Why do I need to add estimated time when I create a new task?",
        description: (
          <div>
            <p>
              “Estimated time” is a mandatory field in certain project and task
              management systems, so it must be specified during the creation of
              a task. Also, the “Estimated time” indication will allow you more
              accurately plan your work for the current task.
            </p>
          </div>
        ),
      },
      {
        title: `I work for several tasks every day and tired to add few tasks to plannings every time, can I do it faster and easier?`,
        description: (
          <div>
            <p>
              Yes, you can add earlier planned tasks faster than from Recents or
              Favorites tab in one click. Blue notification "Repeat yesterday's
              tasks" will appear on next day on main screen after launching
              DueFocus desktop app. Click on it and yesterday's plannings will
              automatically repeated with the same planned time.
            </p>
          </div>
        ),
      },
      {
        title: `What should I do if I had to work overtime more than 8 planned hours, but missed to update planned time and my task was stopped?`,
        description: (
          <div>
            <p>
              In cases when you may work overtime - turn off "Pause Task if
              Planned is Reached" switcher in Preferences on Desktop
              application, and Overspent mode will be enabled.
            </p>
            <p>
              While reporting task with overspent time you can include or
              discard exceeded time.
            </p>
          </div>
        ),
      },
      {
        title: "Can I remove tracked time from timeline?",
        description: (
          <div>
            <p>
              No, deleting timesheets is unavailable. DueFocus is a daily
              planner, all your day time can be used only once and you can't
              change it after.
            </p>
          </div>
        ),
      },
      {
        title:
          "I cannot find my task(s) in a project through DueFocus. What's wrong?",
        description: (
          <div>
            <p>
              DueFocus displays only open tasks and assigned on you by default
              while adding task to plannings or creating manual report. To
              display unassigned tasks please mark/unmark Unassigned checkbox
              after selecting project.
            </p>
            <p>Verify that:</p>
            <p>
              1. Your task is present in your task/project management system;
            </p>
            <p>
              2. Task is opened and not in
              Closed/Done/Cancelled/Rejected/Archived status;
            </p>
            <p>
              3. Task is assigned on you, or unassigned. Tasks assigned on
              another user are not available for you. But if your system
              supports multiassigning task to several users, and task is
              assigned also on you - it will be displayed;
            </p>
            <p>
              4. There is no problem with your integration. When integration is
              failed - it's marked by red on Settings - Integrations tab both on
              desktop app and Dashboard.;
            </p>
            <br />
            <p>
              If the listed steps above didn't help and you still don't see your
              task, please write to our DueFocus support team via Intercom chat
              in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `How can I report time manually?`,
        description: (
          <div>
            <span className={styles.accordion_title}>
              You can contact our “Help Center”:
            </span>
            <p>To use manual reporting, you should:</p>
            <p>
              1. By going to the “Info” section and clicking the “Help Center”
              button;
            </p>
            <p>
              2. Select project and task, if task is unassigned and not
              displayed in list - mark "Show unassigned" checkbox;
            </p>
            <p>
              2a. As alternative to step 2, paste task URL in search field
              instead searching
            </p>
            <p>3. Fill comment and click “Submit” form</p>
            <br />
            <p>
              Blue timesheet will be created and time will be reported into
              task.
            </p>
            <p>
              Note that amount of allowed manually reported time can be limited
              by project settings.
            </p>
          </div>
        ),
      },
      {
        title: `What is Cold Start?`,
        description: (
          <div>
            <p>
              Cold Start is feature which allows start tracking time much faster
              without setting task and planned time, just start track time in
              one click and reassign time into your task later.
            </p>
            <p>
              Also you can turn on "Cold Start on System Start" switcher, and
              time will begin trackin automatically on system startup.
            </p>
            <p>
              This feature is useful when you need to fast begin tracking, in
              cases when you didn't get yet a task from your manager but you
              already need to work, or when there is a trouble with your
              task/project management system.
            </p>
            <p>
              While Cold Start is not reassigned - all your activities and
              screenshots are private and not visible for another team members
              and managers. After reassigning Cold Star all your information
              becomes available.
            </p>
          </div>
        ),
      },
      {
        title:
          "How to launch Cold Start tracking time and reassign it to task?",
        description: (
          <div>
            <p>
              To launch Cold Start tracking click on Start button on the main
              screen of desktop application. Also you can turn on "Cold Start on
              System Start" switcher, and time will begin tracking automatically
              on system startup.
            </p>
            <p>
              Time will begin tracking with creating cyan timesheets on the
              timeline. You may set screenshot frequency in web application on
              "Сold Project" settings page and Idle detection on web and desktop
              appication.
            </p>
            <p>To report Cold start you have to:</p>
            <p>- On desktop app:</p>
            <p>1. Click on blue "Report" button near Cold Start timer;</p>
            <p>
              2. Select project and task (note that Cold Project is your private
              project and will not visible to anyone);
            </p>
            <p>
              3. Select how much you need to report time in
              selector(optionally), leave comment and click on "Assign Time"
              button.
            </p>
            <p>- On Dashboard:</p>
            <p>1. Go to Time - Timeline (or Reports);</p>
            <p>
              2. Select date with not reassigned time (dates are marked in
              blue);
            </p>
            <p>
              3. Select cyan timesheets on timeline (for separated reporting) or
              click on "Assign Cold Time" button (to report all time into one
              task);
            </p>
            <p>
              4. Select project and task (note that Cold Project is your private
              project and will not visible to anyone);
            </p>
            <p>
              5. Select how much you need to report time in by timepickers
              (optionally), leave comment and click on "Submit" button.
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: Cold Project is temporary private project just for settings
              while tracking Cold Start and for reporting redundant cold tracked
              time. Please don't report time into it if you are working on a
              project with manager and other employees, otherwise they cannot
              see your activities.
            </span>
          </div>
        ),
      },
      {
        title: "What is Focus feature?",
        description: (
          <div>
            <p>
              Focus is a unique Machine Learning Algorithm for assessing the
              involvement and focus of a person at work in the process of
              working on his task from integration task managers (Jira. Trello,
              Asana.CickUp etc).
            </p>
            <p>
              We just begun collecting data and ML still learning, as soon as we
              collect information we classify the assessment and the norms of
              work for each individual role, now it trained by users, but in the
              future we can automate it.
            </p>
          </div>
        ),
      },
      {
        title: `What’s Other activity?`,
        description: (
          <div>
            <p>
              Other activity refers to work done in applications and websites
              for which the keywords were not listed in the “Work”,
              “Communication”, “Learning”, and “Entertainment” categories.
            </p>
            <p>
              You can drag & drop any title to needed category in project
              settings.
            </p>
          </div>
        ),
      },
      {
        title:
          "Why DueFocus recognizes our activities as Respite/Useless?. Our work is based on social networks and we use Youtube as tutorials.",
        description: (
          <div>
            <p>
              Activity categories are set by default in project settings. In
              most of cases Youtube, social networks, internet stores, fun
              services etc are useless for employees and distract them from main
              job.
            </p>
            <p>
              But if you have specific workflow on your project - you can easily
              change category by moving titles from one category to another.
            </p>
            <p>
              Also you may copy saved changes for categories from one project to
              another.
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: Only members with manager rights can edit project settings
              (Gear button is marked as blue for managers)
            </span>
          </div>
        ),
      },
      {
        title: `How to enable screenshotting?`,
        description: (
          <div>
            <p>
              First of all user should connect his Google Drive or Dropbox
              storage. Note that two storages cannot be connected to one
              account, and the same storage cannot be connected to different
              DueFocus accounts.
            </p>
            <p>
              Also user with manager rights in project should set screenshot
              frequency more than "None". manager can set common frequency for
              all users in a project or set for separated users individually.
            </p>
            <p>
              Users can observe their own screenshots on Time - Timeline tab on
              Dashboard. Manager can observe screenshots of his employees on
              Team Pulse - Team Screens and select required user in selector.
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: If screenshot frequency is set to None, user can enable
              screenshotting on desktop by turning on switcher in menu on
              "Screenshot" item
            </span>
          </div>
        ),
      },
      {
        title: `How many screenshots DueFocus captures during tracking?`,
        description: (
          <div>
            <p>
              You can set frequency of screenshotting in project settings. You
              can set 1x, 2x or 3x screenshots per 10 minutes, or set 'None' and
              screenshot will not be made
            </p>
            <br />
            <span className={styles.accordion_title}>
              Note: If screenshot frequency is set to None, user can enable
              screenshotting on desktop by turning on switcher in menu on
              "Screenshot" item
            </span>
          </div>
        ),
      },
      {
        title: `I just connected integration, but but my projects and tasks are still not displayed, what I have to do?`,
        description: (
          <div>
            <p>
              Please refresh the page or click on Reload button on Projects
              page. After these steps info will be updated and your projects and
              tasks will be displayed.
            </p>
            <p>
              If the listed steps above didn't help and you still don't see your
              projecs and tasks, please write to our support team via Intercom
              chat in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `Seems my integration has failed and marked as red, I cannot select a project to start a task or create manual report. What's wrong?`,
        description: (
          <div>
            <p>
              If you see red alert on Settings tab on Dashboard or your profile
              icon and Integration item in menu on desktop - it means that there
              is a some problem with your connected integration.
            </p>
            <p>
              Please click on Reload button on Dashboard. If it doesn't help -
              try to reconnect or disconnect you integration and connect again.
            </p>
            <p>
              If these steps don't resolve and issue - please write to our
              DueFocus support team via Intercom chat in upper right corner.
            </p>
          </div>
        ),
      },
      {
        title: `How to remove my acount?`,
        description: (
          <div>
            <p>
              We don’t have a final deletion of accounts. Over time without
              using it, account changes status to Archived.
            </p>
            <p>
              This is to ensure that if you worked as a team member, and left
              the project, then the project manager and your colleagues had
              information about you.
            </p>
            <p>
              But if you want, we can remove your account data from base. Just
              write to our DueFocus support team via Intercom chat in upper
              right corner.
            </p>
            <br />
            <p>
              Also we are very glad to know was wrong in your work with
              DueFocus? Your feedback will be a massive contribution in our
              improvement.
            </p>
          </div>
        ),
      },
      {
        title: `I haven't found an answer to my question. What should I do? `,
        description: (
          <div>
            <p>
              If you have some troubleshootings, issues, bugs, questions or
              suggestions, please write to our DueFocus support team via
              Intercom chat in upper right corner.
            </p>
            <p>
              Also you can add requests and feedback here:{" "}
              <a href="https://duefocus.canny.io">https://duefocus.canny.io</a>
            </p>
          </div>
        ),
      },
    ]

    return (
      <>
        <EmptyBlock />
        <div className={styles.main}>
          <div className={styles.header}>
            <h1>Frequently Asked Questions</h1>
            <div>
              <div>
                <div onClick={this.goToDesktop}>Desktop</div>
                <div onClick={this.goToWeb}>Web</div>
              </div>
              <div>
                {/* <div onClick={this.goToExtension}>Extension</div> */}
                <div onClick={this.goToIntegrations}>Integrations</div>
              </div>
            </div>
          </div>
          <div className={styles.title}>
            <img src={desktop} width="auto" height="auto" alt="desktop" />
            <h2>Desktop</h2>
          </div>
          <div className={styles.accordion}>
            <Accordion>
              {content.map((item, n) => (
                <AccordionItem key={n}>
                  <AccordionItemTitle>
                    <span className="acc_header u-position-relative">
                      {item.title}
                      <div className="accordion__arrow" role="presentation" />
                    </span>
                  </AccordionItemTitle>
                  <AccordionItemBody>{item.description}</AccordionItemBody>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          <Web />
          <Extension />
        </div>
        <div className={styles.blockForm}>
          <Form mainTitle={mainTitle} />
        </div>
        <Footer />
      </>
    )
  }
}
